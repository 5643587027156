import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router';
import patientService from "../Redux/services/patientService";
import Abdomen from "./../Assets/Images/Abdomen.jpg";
import { useDispatch, useSelector } from "react-redux";

function ViewPEPopup(props) {

    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const [isLoading, setLoad] = useState(false)
    const goBack = () => {
        history.goBack();
    }
    const userData = useSelector((state) => state.authReducer.userData);
    const handleChangePE = (e) => {
        setAddPhysicalExam({ ...addPhysicalExam, [e.target.name]: e.target.value });
    };

    const [addPhysicalExam, setAddPhysicalExam] = useState({});
    const [listPhysicalExam, setListPhysicalExam] = useState();

    useEffect(() => {
        let payload = {
            "appointmentId": props.data.id
        }
        patientService.listPhysicalExamination(payload).then((res) => {
            if (res.data.length) {
                setListPhysicalExam(res.data[0])
            }
        })
    }, []);
    return (
        <>
            <div class="flex">

                <div className="flex w-full">
                    <div className="-my-2 mx-4 sm:-mx-4 lg:-mx-6 w-full">
                        <div className="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
                            <div class="flex justify-between my-3">
                                <div className='flex'>
                                    <div className="lg:hidden relative  mr-4 ml-2 top-0" >
                                    </div>
                                    <p class="md:flex lg:block text-gray-700 text-base font-medium font-rubik">
                                        Physical Exmaination </p>
                                </div>

                                <div class="flex justify-between">
                                    {/* <p onClick={openFilter} class="text-sm text-brand-secondary font-medium font-rubik cursor-pointer">Add New +</p> */}
                                    {/* <p onClick={viewNotification} class="text-sm ml-5 text-brand-secondary font-medium font-rubik cursor-pointer">View</p> */}
                                </div>
                            </div>
                            <div class="w-full h-112 lg:mb-2 border border-gray-200 rounded-xl mb-16 antialiased justify-between">
                                {listPhysicalExam &&
                                    <div class="mx-6">
                                        <div class="flex justify-between my-3">
                                            <p class="text-lg font-small text-brand-secondary">General</p>
                                        </div>
                                        <div class="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                                            <div class="w-11/12 mb-4">
                                                <div class="flex space-x-3">
                                                    <p class="text-xs font-rubik font-medium text-gray-primary">
                                                        Eyes
                                                    </p>
                                                </div>
                                                <div class="pt-4">
                                                    <div class="flex justify-between h-3  h-full">
                                                        <select
                                                            className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                            id="eye"
                                                            name="eye"
                                                            disabled={true}
                                                            value={listPhysicalExam.general.eye}
                                                            onChange={handleChangePE} >
                                                            <option class="" value="">{" Select options"}</option>
                                                            <option class="py-1" value="Pallor">Pallor</option>
                                                            <option class="py-1" value="Redness">Redness</option>
                                                            <option class="py-1" value="Itching">Itching</option>
                                                            <option class="py-1" value="Swelling">Swelling</option>
                                                            <option class="py-1" value="Icterus">Icterus</option>
                                                            <option class="py-1" value="Cyanosis">Cyanosis</option>
                                                            <option class="py-1" value="Normal">Normal</option>
                                                        </select>
                                                    </div>
                                                    <hr class=" bg-gray-secondary" />
                                                </div>
                                            </div>
                                            <div class="w-11/12 mb-4">
                                                <div class="flex space-x-3">
                                                    <p class="text-xs font-rubik font-medium text-gray-primary">
                                                        Mouth
                                                    </p>
                                                </div>
                                                <div class="pt-4">
                                                    <div class="flex justify-between h-3  h-full">
                                                        <select
                                                            className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                            id="mouth"
                                                            name="mouth"
                                                            disabled={true}
                                                            value={listPhysicalExam.general.mouth}
                                                            onChange={handleChangePE} >
                                                            <option class="" value="">{" Select options   "}</option>
                                                            <option class="py-1" value="Pallor">Pallor</option>
                                                            <option class="py-1" value="Redness">Redness</option>
                                                            <option class="py-1" value="Itching">Itching</option>
                                                            <option class="py-1" value="Swelling">Swelling</option>
                                                            <option class="py-1" value="Icterus">Icterus</option>
                                                            <option class="py-1" value="Cyanosis">Cyanosis</option>
                                                            <option class="py-1" value="Normal">Normal</option>
                                                        </select>
                                                    </div>
                                                    <hr class=" bg-gray-secondary" />
                                                </div>
                                            </div>
                                            <div class="w-11/12 mb-4">
                                                <div class="flex space-x-3">
                                                    <p class="text-xs font-rubik font-medium text-gray-primary">
                                                        Hands
                                                    </p>
                                                </div>
                                                <div class="pt-4">
                                                    <div class="flex justify-between h-3  h-full">
                                                        <select
                                                            className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                            id="hands"
                                                            name="hands"
                                                            disabled={true}
                                                            value={listPhysicalExam.general.hands}
                                                            onChange={handleChangePE} >
                                                            <option class="" value="">{" Select options  "}</option>
                                                            <option class="py-1" value="Pallor">Pallor</option>
                                                            <option class="py-1" value="Redness">Redness</option>
                                                            <option class="py-1" value="Itching">Itching</option>
                                                            <option class="py-1" value="Swelling">Swelling</option>
                                                            <option class="py-1" value="Icterus">Icterus</option>
                                                            <option class="py-1" value="Cyanosis">Cyanosis</option>
                                                            <option class="py-1" value="Normal">Normal</option>
                                                        </select>
                                                    </div>
                                                    <hr class=" bg-gray-secondary" />
                                                </div>
                                            </div>
                                            <div class="w-11/12 mb-4">
                                                <div class="flex space-x-3">
                                                    <p class="text-xs font-rubik font-medium text-gray-primary">
                                                        Feet
                                                    </p>
                                                </div>
                                                <div class="pt-4">
                                                    <div class="flex justify-between h-3  h-full">
                                                        <select
                                                            className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                            id="feet"
                                                            name="feet"
                                                            value={listPhysicalExam.general.feet}
                                                            disabled={true}
                                                            onChange={handleChangePE} >
                                                            <option class="" value="">{" Select options  "}</option>
                                                            <option class="py-1" value="Pallor">Pallor</option>
                                                            <option class="py-1" value="Redness">Redness</option>
                                                            <option class="py-1" value="Itching">Itching</option>
                                                            <option class="py-1" value="Swelling">Swelling</option>
                                                            <option class="py-1" value="Icterus">Icterus</option>
                                                            <option class="py-1" value="Cyanosis">Cyanosis</option>
                                                            <option class="py-1" value="Oedema">Oedema</option>
                                                            <option class="py-1" value="Normal">Normal</option>
                                                        </select>
                                                    </div>
                                                    <hr class=" bg-gray-secondary" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                                            {/* <div class="w-11/12 mb-4">
                                            <div class="flex space-x-3">
                                                <p class="text-xs font-rubik font-medium text-gray-primary">
                                                    Head & Neck
                                                </p>
                                            </div>
                                            <div class="pt-4">
                                                <div class="flex justify-between h-3  h-full">
                                                    <select
                                                        className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                        id="head"
                                                        name="head"
                                                        value={listPhysicalExam.general.head}
                                                        disabled={true}
                                                        onChange={handleChangePE} >
                                                        <option class="" value="">{" Select options"}</option>
                                                        <option class="py-1" value="Pallor">Pallor</option>
                                                        <option class="py-1" value="Redness">Redness</option>
                                                        <option class="py-1" value="Itching">Itching</option>
                                                        <option class="py-1" value="Swelling">Swelling</option>
                                                        <option class="py-1" value="Icterus">Icterus</option>
                                                        <option class="py-1" value="Cyanosis">Cyanosis</option>
                                                        <option class="py-1" value="Normal">Normal</option>
                                                    </select>
                                                </div>
                                                <hr class=" bg-gray-secondary" />
                                            </div>
                                        </div> */}
                                            <div class="w-11/12 mb-4">
                                                <div class="flex space-x-3">
                                                    <p class="text-xs font-rubik font-medium text-gray-primary">
                                                        Lymph nodes of head & neck
                                                    </p>
                                                </div>
                                                <div class="pt-4">
                                                    <div class="flex justify-between h-3  h-full">
                                                        <select
                                                            className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                            id="lymphnodes"
                                                            name="lymphnodes"
                                                            disabled={true}
                                                            value={listPhysicalExam.general.lymphnodes}
                                                            onChange={handleChangePE} >
                                                            <option class="" value="">{" Select options   "}</option>
                                                            <option class="py-1" value="Swollen">Swollen</option>
                                                            <option class="py-1" value="Normal">Normal</option>
                                                        </select>
                                                    </div>
                                                    <hr class=" bg-gray-secondary" />
                                                </div>
                                            </div>

                                        </div>

                                        <div class="flex justify-between my-3">
                                            <p class="text-lg font-small text-brand-secondary">Respiratory</p>
                                        </div>
                                        <div class="flex justify-between my-3 mb-3">
                                            <p class="text-lg font-small text-black">Upper Respiratory </p>
                                        </div>
                                        <div class="flex space-x-3 mb-3">
                                            <p class="text-xs font-rubik font-medium text-gray-primary">
                                                Sinuses :
                                            </p>
                                        </div>
                                        <div class="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                                            <div class="w-11/12 mb-4">
                                                <div class="flex space-x-3">
                                                    <p class="text-xs font-rubik font-medium text-gray-primary">
                                                        Frontal
                                                    </p>
                                                </div>
                                                <div class="pt-4">
                                                    <div class="flex justify-between h-3  h-full">
                                                        <select
                                                            className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                            id="frontal"
                                                            name="frontal"
                                                            disabled={true}
                                                            value={listPhysicalExam.respiratory.frontal}
                                                            onChange={handleChangePE} >
                                                            <option class="" value="">{" Select options"}</option>
                                                            <option class="py-1" value="Painful">Painful</option>
                                                            <option class="py-1" value="Normal">Normal</option>
                                                        </select>
                                                    </div>
                                                    <hr class=" bg-gray-secondary" />
                                                </div>
                                            </div>
                                            <div class="w-11/12 mb-4">
                                                <div class="flex space-x-3">
                                                    <p class="text-xs font-rubik font-medium text-gray-primary">
                                                        Ethmoid
                                                    </p>
                                                </div>
                                                <div class="pt-4">
                                                    <div class="flex justify-between h-3  h-full">
                                                        <select
                                                            className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                            id="ethmoid"
                                                            name="ethmoid"
                                                            disabled={true}
                                                            value={listPhysicalExam.respiratory.ethmoid}
                                                            onChange={handleChangePE} >
                                                            <option class="" value="">{" Select options"}</option>
                                                            <option class="py-1" value="Painful">Painful</option>
                                                            <option class="py-1" value="Normal">Normal</option>
                                                        </select>
                                                    </div>
                                                    <hr class=" bg-gray-secondary" />
                                                </div>
                                            </div>
                                            <div class="w-11/12 mb-4">
                                                <div class="flex space-x-3">
                                                    <p class="text-xs font-rubik font-medium text-gray-primary">
                                                        Maxillary
                                                    </p>
                                                </div>
                                                <div class="pt-4">
                                                    <div class="flex justify-between h-3  h-full">
                                                        <select
                                                            className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                            id="maxillary"
                                                            name="maxillary"
                                                            disabled={true}
                                                            value={listPhysicalExam.respiratory.maxillary}
                                                            onChange={handleChangePE} >
                                                            <option class="" value="">{" Select options"}</option>
                                                            <option class="py-1" value="Painful">Painful</option>
                                                            <option class="py-1" value="Normal">Normal</option>
                                                        </select>
                                                    </div>
                                                    <hr class=" bg-gray-secondary" />
                                                </div>
                                            </div>
                                            <div class="w-11/12 mb-4">
                                                <div class="flex space-x-3">
                                                    <p class="text-xs font-rubik font-medium text-gray-primary">
                                                        Sphenoid
                                                    </p>
                                                </div>
                                                <div class="pt-4">
                                                    <div class="flex justify-between h-3  h-full">
                                                        <select
                                                            className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                            id="sphenoid"
                                                            name="sphenoid"
                                                            disabled={true}
                                                            value={listPhysicalExam.respiratory.sphenoid}
                                                            onChange={handleChangePE} >
                                                            <option class="" value="">{" Select options"}</option>
                                                            <option class="py-1" value="Painful">Painful</option>
                                                            <option class="py-1" value="Normal">Normal</option>
                                                        </select>
                                                    </div>
                                                    <hr class=" bg-gray-secondary" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                                            <div class="w-11/12 mb-4">
                                                <div class="flex space-x-3">
                                                    <p class="text-xs font-rubik font-medium text-gray-primary">
                                                        Nasal cavity
                                                    </p>
                                                </div>
                                                <div class="pt-4">
                                                    <div class="flex justify-between h-3  h-full">
                                                        <select
                                                            className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                            id="nasalCavity"
                                                            name="nasalCavity"
                                                            disabled={true}
                                                            value={listPhysicalExam.respiratory.nasalCavity}
                                                            onChange={handleChangePE} >
                                                            <option class="" value="">{" Select options"}</option>
                                                            <option class="py-1" value="Congested">Congested</option>
                                                            <option class="py-1" value="Normal">Normal</option>
                                                        </select>
                                                    </div>
                                                    <hr class=" bg-gray-secondary" />
                                                </div>
                                            </div>
                                            <div class="w-11/12 mb-4">
                                                <div class="flex space-x-3">
                                                    <p class="text-xs font-rubik font-medium text-gray-primary">
                                                        Nasal septum
                                                    </p>
                                                </div>
                                                <div class="pt-4">
                                                    <div class="flex justify-between h-3  h-full">
                                                        <select
                                                            className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                            id="nasalSeptum"
                                                            name="nasalSeptum"
                                                            disabled={true}
                                                            value={listPhysicalExam.respiratory.nasalSeptum}
                                                            onChange={handleChangePE} >
                                                            <option class="" value="">{" Select options"}</option>
                                                            <option class="py-1" value="Central">Central</option>
                                                            <option class="py-1" value="Deviated">Deviated</option>
                                                        </select>
                                                    </div>
                                                    <hr class=" bg-gray-secondary" />
                                                </div>
                                            </div>
                                            <div class="w-11/12 mb-4">
                                                <div class="flex space-x-3">
                                                    <p class="text-xs font-rubik font-medium text-gray-primary">
                                                        Pharynx
                                                    </p>
                                                </div>
                                                <div class="pt-4">
                                                    <div class="flex justify-between h-3  h-full">
                                                        <select
                                                            className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                            id="pharynx"
                                                            name="pharynx"
                                                            disabled={true}
                                                            value={listPhysicalExam.respiratory.pharynx}
                                                            onChange={handleChangePE} >
                                                            <option class="" value="">{" Select options"}</option>
                                                            <option class="py-1" value="Congested">Congested</option>
                                                            <option class="py-1" value="Normal">Normal</option>
                                                        </select>
                                                    </div>
                                                    <hr class=" bg-gray-secondary" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex justify-between my-3 mb-3">
                                            <p class="text-lg font-small text-black">Lower Respiratory </p>
                                        </div>
                                        <div class="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                                            <div class="w-11/12 mb-4">
                                                <div class="flex space-x-3">
                                                    <p class="text-xs font-rubik font-medium text-gray-primary">
                                                        Digital Stethoscope
                                                    </p>
                                                </div>
                                                <div class="pt-4">
                                                    <div class="flex justify-between h-3  h-full">
                                                        <select
                                                            className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                            id="digitalStethoscope"
                                                            name="digitalStethoscope"
                                                            disabled={true}
                                                            value={listPhysicalExam.respiratory.digitalStethoscope}
                                                            onChange={handleChangePE} >
                                                            <option class="" value="">{" Select options"}</option>
                                                            <option class="py-1" value="Normal">Normal</option>
                                                            <option class="py-1" value="Abnormal">Abnormal</option>
                                                        </select>
                                                    </div>
                                                    <hr class=" bg-gray-secondary" />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="flex justify-between my-3">
                                            <p class="text-lg font-small text-brand-secondary">Gastrointestinal system</p>
                                        </div>
                                        <div class="flex justify-between my-3 mb-3">
                                            <p class="text-lg font-small text-black">Oral Cavity </p>
                                        </div>
                                        <div class="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                                            <div class="w-11/12 mb-4">
                                                <div class="flex space-x-3">
                                                    <p class="text-xs font-rubik font-medium text-gray-primary">
                                                        Lips
                                                    </p>
                                                </div>
                                                <div class="pt-4">
                                                    <div class="flex justify-between h-3  h-full">
                                                        <select
                                                            className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                            id="lips"
                                                            name="lips"
                                                            disabled={true}
                                                            value={listPhysicalExam.abdomen.oralCavity.lips}
                                                            onChange={handleChangePE} >
                                                            <option class="" value="">{" Select options"}</option>
                                                            <option class="py-1" value="Normal">Normal</option>
                                                            <option class="py-1" value="Abnormal">Abnormal</option>
                                                        </select>
                                                    </div>
                                                    <hr class=" bg-gray-secondary" />
                                                </div>
                                            </div>
                                            <div class="w-11/12 mb-4">
                                                <div class="flex space-x-3">
                                                    <p class="text-xs font-rubik font-medium text-gray-primary">
                                                        Buccal mucosa
                                                    </p>
                                                </div>
                                                <div class="pt-4">
                                                    <div class="flex justify-between h-3  h-full">
                                                        <select
                                                            className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                            id="buccalMucosa"
                                                            name="buccalMucosa"
                                                            disabled={true}
                                                            value={listPhysicalExam.abdomen.oralCavity.buccalMucosa}
                                                            onChange={handleChangePE} >
                                                            <option class="" value="">{" Select options"}</option>
                                                            <option class="py-1" value="Normal">Normal</option>
                                                            <option class="py-1" value="Abnormal">Abnormal</option>
                                                        </select>
                                                    </div>
                                                    <hr class=" bg-gray-secondary" />
                                                </div>
                                            </div>
                                            <div class="w-11/12 mb-4">
                                                <div class="flex space-x-3">
                                                    <p class="text-xs font-rubik font-medium text-gray-primary">
                                                        Gums - Gingivolabial surface
                                                    </p>
                                                </div>
                                                <div class="pt-4">
                                                    <div class="flex justify-between h-3  h-full">
                                                        <select
                                                            className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                            id="gingivolabialSurface"
                                                            name="gingivolabialSurface"
                                                            disabled={true}
                                                            value={listPhysicalExam.abdomen.gums.gingivolabialSurface}
                                                            onChange={handleChangePE} >
                                                            <option class="" value="">{" Select options"}</option>
                                                            <option class="py-1" value="Normal">Normal</option>
                                                            <option class="py-1" value="Abnormal">Abnormal</option>
                                                        </select>
                                                    </div>
                                                    <hr class=" bg-gray-secondary" />
                                                </div>
                                            </div>
                                            <div class="w-11/12 mb-4">
                                                <div class="flex space-x-3">
                                                    <p class="text-xs font-rubik font-medium text-gray-primary">
                                                        Gums - Gingivobuccal surface
                                                    </p>
                                                </div>
                                                <div class="pt-4">
                                                    <div class="flex justify-between h-3  h-full">
                                                        <select
                                                            className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                            id="gingivobuccalSurface"
                                                            name="gingivobuccalSurface"
                                                            disabled={true}
                                                            value={listPhysicalExam.abdomen.gums.gingivobuccalSurface}
                                                            onChange={handleChangePE} >
                                                            <option class="" value="">{" Select options"}</option>
                                                            <option class="py-1" value="Normal">Normal</option>
                                                            <option class="py-1" value="Abnormal">Abnormal</option>
                                                        </select>
                                                    </div>
                                                    <hr class=" bg-gray-secondary" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                                            <div class="w-11/12 mb-4">
                                                <div class="flex space-x-3">
                                                    <p class="text-xs font-rubik font-medium text-gray-primary">
                                                        Teeth
                                                    </p>
                                                </div>
                                                <div class="pt-4">
                                                    <div class="flex justify-between h-3  h-full">
                                                        <select
                                                            className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                            id="teeth"
                                                            name="teeth"
                                                            disabled={true}
                                                            value={listPhysicalExam.abdomen.oralCavity.teeth}
                                                            onChange={handleChangePE} >
                                                            <option class="" value="">{" Select options"}</option>
                                                            <option class="py-1" value="Normal">Normal</option>
                                                            <option class="py-1" value="Abnormal">Abnormal</option>
                                                        </select>
                                                    </div>
                                                    <hr class=" bg-gray-secondary" />
                                                </div>
                                            </div>
                                            <div class="w-11/12 mb-4">
                                                <div class="flex space-x-3">
                                                    <p class="text-xs font-rubik font-medium text-gray-primary">
                                                        Tongue
                                                    </p>
                                                </div>
                                                <div class="pt-4">
                                                    <div class="flex justify-between h-3  h-full">
                                                        <select
                                                            className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                            id="tongue"
                                                            name="tongue"
                                                            disabled={true}
                                                            value={listPhysicalExam.abdomen.oralCavity.tongue}
                                                            onChange={handleChangePE} >
                                                            <option class="" value="">{" Select options"}</option>
                                                            <option class="py-1" value="Normal">Normal</option>
                                                            <option class="py-1" value="Abnormal">Abnormal</option>
                                                        </select>
                                                    </div>
                                                    <hr class=" bg-gray-secondary" />
                                                </div>
                                            </div>
                                            <div class="w-11/12 mb-4">
                                                <div class="flex space-x-3">
                                                    <p class="text-xs font-rubik font-medium text-gray-primary">
                                                        Hard palate
                                                    </p>
                                                </div>
                                                <div class="pt-4">
                                                    <div class="flex justify-between h-3  h-full">
                                                        <select
                                                            className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                            id="hardPalate"
                                                            name="hardPalate"
                                                            disabled={true}
                                                            value={listPhysicalExam.abdomen.gums.hardPalate}
                                                            onChange={handleChangePE} >
                                                            <option class="" value="">{" Select options"}</option>
                                                            <option class="py-1" value="Normal">Normal</option>
                                                            <option class="py-1" value="Abnormal">Abnormal</option>
                                                        </select>
                                                    </div>
                                                    <hr class=" bg-gray-secondary" />
                                                </div>
                                            </div>
                                            <div class="w-11/12 mb-4">
                                                <div class="flex space-x-3">
                                                    <p class="text-xs font-rubik font-medium text-gray-primary">
                                                        Floor of the tongue
                                                    </p>
                                                </div>
                                                <div class="pt-4">
                                                    <div class="flex justify-between h-3  h-full">
                                                        <select
                                                            className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                            id="floorOfTheTongue"
                                                            name="floorOfTheTongue"
                                                            disabled={true}
                                                            value={listPhysicalExam.abdomen.oralCavity.floorOfTheTongue}
                                                            onChange={handleChangePE} >
                                                            <option class="" value="">{" Select options"}</option>
                                                            <option class="py-1" value="Normal">Normal</option>
                                                            <option class="py-1" value="Abnormal">Abnormal</option>
                                                        </select>
                                                    </div>
                                                    <hr class=" bg-gray-secondary" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex justify-between my-3 mb-3">
                                            <p class="text-lg font-small text-black">Inspect the Abdomen </p>
                                        </div>
                                        <div class="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                                            <div class="w-11/12 mb-4">
                                                <div class="flex space-x-3">
                                                    <p class="text-xs font-rubik font-medium text-gray-primary">
                                                        Linea alba
                                                    </p>
                                                </div>
                                                <div class="pt-4">
                                                    <div class="flex justify-between h-3  h-full">
                                                        <select
                                                            className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                            id="lineaAlba"
                                                            name="lineaAlba"
                                                            disabled={true}
                                                            value={listPhysicalExam.abdomen.abdomenInspection.lineaAlba}
                                                            onChange={handleChangePE} >
                                                            <option class="" value="">{" Select options"}</option>
                                                            <option class="py-1" value="Normal">Normal</option>
                                                            <option class="py-1" value="Abnormal">Abnormal</option>
                                                        </select>
                                                    </div>
                                                    <hr class=" bg-gray-secondary" />
                                                </div>
                                            </div>
                                            <div class="w-11/12 mb-4">
                                                <div class="flex space-x-3">
                                                    <p class="text-xs font-rubik font-medium text-gray-primary">
                                                        Skin of the abdomen
                                                    </p>
                                                </div>
                                                <div class="pt-4">
                                                    <div class="flex justify-between h-3  h-full">
                                                        <select
                                                            className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                            id="abdomenSkin"
                                                            name="abdomenSkin"
                                                            disabled={true}
                                                            value={listPhysicalExam.abdomen.abdomenInspection.abdomenSkin}
                                                            onChange={handleChangePE} >
                                                            <option class="" value="">{" Select options"}</option>
                                                            <option class="py-1" value="Normal">Normal</option>
                                                            <option class="py-1" value="Abnormal">Abnormal</option>
                                                        </select>
                                                    </div>
                                                    <hr class=" bg-gray-secondary" />
                                                </div>
                                            </div>
                                            <div class="w-11/12 mb-4">
                                                <div class="flex space-x-3">
                                                    <p class="text-xs font-rubik font-medium text-gray-primary">
                                                        Surgical Scars
                                                    </p>
                                                </div>
                                                <div class="pt-4">
                                                    <div class="flex justify-between h-3  h-full">
                                                        <select
                                                            className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                            id="surgicalScars"
                                                            name="surgicalScars"
                                                            disabled={true}
                                                            value={listPhysicalExam.abdomen.abdomenInspection.surgicalScars}
                                                            onChange={handleChangePE} >
                                                            <option class="" value="">{" Select options"}</option>
                                                            <option class="py-1" value="Present">Present</option>
                                                            <option class="py-1" value="Absent">Absent</option>
                                                        </select>
                                                    </div>
                                                    <hr class=" bg-gray-secondary" />
                                                </div>
                                            </div>
                                            <div class="w-11/12 mb-4">
                                                <div class="flex space-x-3">
                                                    <p class="text-xs font-rubik font-medium text-gray-primary">
                                                        Umblicus
                                                    </p>
                                                </div>
                                                <div class="pt-4">
                                                    <div class="flex justify-between h-3  h-full">
                                                        <select
                                                            className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                            id="umblicus"
                                                            name="umblicus"
                                                            disabled={true}
                                                            value={listPhysicalExam.abdomen.abdomenInspection.umblicus}
                                                            onChange={handleChangePE} >
                                                            <option class="" value="">{" Select options"}</option>
                                                            <option class="py-1" value="Normal">Normal</option>
                                                            <option class="py-1" value="Everted">Everted</option>
                                                        </select>
                                                    </div>
                                                    <hr class=" bg-gray-secondary" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                                            <div class="w-11/12 mb-4">
                                                <div class="flex space-x-3">
                                                    <p class="text-xs font-rubik font-medium text-gray-primary">
                                                        Abdominal Distension
                                                    </p>
                                                </div>
                                                <div class="pt-4">
                                                    <div class="flex justify-between h-3  h-full">
                                                        <select
                                                            className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                            id="abdominalDistension"
                                                            name="abdominalDistension"
                                                            value={listPhysicalExam.abdomen.abdomenInspection.abdominalDistension}
                                                            disabled={true}
                                                            onChange={handleChangePE} >
                                                            <option class="" value="">{" Select options"}</option>
                                                            <option class="py-1" value="Present">Present</option>
                                                            <option class="py-1" value="Absent">Absent</option>
                                                        </select>
                                                    </div>
                                                    <hr class=" bg-gray-secondary" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex justify-between my-3 mb-3">
                                            <p class="text-lg font-small text-black">Abdominal Aucultation</p>
                                        </div>
                                        <div class="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                                            <div class="w-11/12 mb-4">
                                                <div class="flex space-x-3">
                                                    <p class="text-xs font-rubik font-medium text-gray-primary">
                                                        Abdominal Sounds/Aortic Bruit
                                                    </p>
                                                </div>
                                                <div class="pt-4">
                                                    <div class="flex justify-between h-3  h-full">
                                                        <select
                                                            className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                            id="abdominalSound"
                                                            name="abdominalSound"
                                                            disabled={true}
                                                            value={listPhysicalExam.abdomen.abdominalSound}
                                                            onChange={handleChangePE} >
                                                            <option class="" value="">{" Select options"}</option>
                                                            <option class="py-1" value="Normal">Normal</option>
                                                            <option class="py-1" value="Abnormal">Abnormal</option>
                                                        </select>
                                                    </div>
                                                    <hr class=" bg-gray-secondary" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex justify-between my-3 mb-3">
                                            <p class="text-lg font-small text-black">Abdominal Palpation - </p>
                                        </div>
                                        <div class="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                                            <div class="w-11/12 mb-4">
                                                <div class="flex space-x-3">
                                                    <p class="text-xs font-rubik font-medium text-gray-primary">
                                                        Palpate for pain, tenderness or local organs
                                                    </p>
                                                </div>
                                                <div class="pt-4">
                                                    <div class="flex justify-between h-3  h-full">
                                                        <select
                                                            className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                            id="palpatePain"
                                                            name="palpatePain"
                                                            disabled={true}
                                                            value={listPhysicalExam.abdomen.abdomenPalpation.palpatePain}
                                                            onChange={handleChangePE} >
                                                            <option class="" value="">{" Select options"}</option>
                                                            <option class="py-1" value="Soft">Soft</option>
                                                            <option class="py-1" value="Tender">Tender</option>
                                                        </select>
                                                    </div>
                                                    <hr class=" bg-gray-secondary" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='grid md:grid-cols-2 lg:grid-cols-4'>
                                            <div className='col-span-3'>
                                                <div class="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
                                                    <div class="w-11/12 mb-4">
                                                        <div class="flex space-x-3">
                                                            <p class="text-xs font-rubik font-medium text-gray-primary">
                                                                Right Hypochondriac (RH)
                                                            </p>
                                                        </div>
                                                        <div class="pt-4">
                                                            <div class="flex justify-between h-3  h-full">
                                                                <select
                                                                    className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                                    id="rightHypochondriac"
                                                                    name="rightHypochondriac"
                                                                    disabled={true}
                                                                    value={listPhysicalExam.abdomen.abdomenPalpation.rightHypochondriac}
                                                                    onChange={handleChangePE} >
                                                                    <option class="" value="">{" Select options"}</option>
                                                                    <option class="py-1" value="Soft">Soft</option>
                                                                    <option class="py-1" value="Tender">Tender</option>
                                                                </select>
                                                            </div>
                                                            <hr class=" bg-gray-secondary" />
                                                        </div>
                                                    </div>
                                                    <div class="w-11/12 mb-4">
                                                        <div class="flex space-x-3">
                                                            <p class="text-xs font-rubik font-medium text-gray-primary">
                                                                Epigastric (E)
                                                            </p>
                                                        </div>
                                                        <div class="pt-4">
                                                            <div class="flex justify-between h-3  h-full">
                                                                <select
                                                                    className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                                    id="epigastric"
                                                                    name="epigastric"
                                                                    disabled={true}
                                                                    value={listPhysicalExam.abdomen.abdomenPalpation.epigastric}
                                                                    onChange={handleChangePE} >
                                                                    <option class="" value="">{" Select options"}</option>
                                                                    <option class="py-1" value="Soft">Soft</option>
                                                                    <option class="py-1" value="Tender">Tender</option>
                                                                </select>
                                                            </div>
                                                            <hr class=" bg-gray-secondary" />
                                                        </div>
                                                    </div>
                                                    <div class="w-11/12 mb-4">
                                                        <div class="flex space-x-3">
                                                            <p class="text-xs font-rubik font-medium text-gray-primary">
                                                                Left Hypochondriac (LH)
                                                            </p>
                                                        </div>
                                                        <div class="pt-4">
                                                            <div class="flex justify-between h-3  h-full">
                                                                <select
                                                                    className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                                    id="leftHypochondriac"
                                                                    name="leftHypochondriac"
                                                                    disabled={true}
                                                                    value={listPhysicalExam.abdomen.abdomenPalpation.leftHypochondriac}
                                                                    onChange={handleChangePE} >
                                                                    <option class="" value="">{" Select options"}</option>
                                                                    <option class="py-1" value="Soft">Soft</option>
                                                                    <option class="py-1" value="Tender">Tender</option>
                                                                </select>
                                                            </div>
                                                            <hr class=" bg-gray-secondary" />
                                                        </div>
                                                    </div>
                                                    <div class="w-11/12 mb-4">
                                                        <div class="flex space-x-3">
                                                            <p class="text-xs font-rubik font-medium text-gray-primary">
                                                                Right Lumbar (RL)
                                                            </p>
                                                        </div>
                                                        <div class="pt-4">
                                                            <div class="flex justify-between h-3  h-full">
                                                                <select
                                                                    className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                                    id="rightLumbar "
                                                                    name="rightLumbar"
                                                                    disabled={true}
                                                                    value={listPhysicalExam.abdomen.abdomenPalpation.rightLumbar}
                                                                    onChange={handleChangePE} >
                                                                    <option class="" value="">{" Select options"}</option>
                                                                    <option class="py-1" value="Soft">Soft</option>
                                                                    <option class="py-1" value="Tender">Tender</option>
                                                                </select>
                                                            </div>
                                                            <hr class=" bg-gray-secondary" />
                                                        </div>
                                                    </div>
                                                    <div class="w-11/12 mb-4">
                                                        <div class="flex space-x-3">
                                                            <p class="text-xs font-rubik font-medium text-gray-primary">
                                                                Umbilical (U)
                                                            </p>
                                                        </div>
                                                        <div class="pt-4">
                                                            <div class="flex justify-between h-3  h-full">
                                                                <select
                                                                    className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                                    id="umbilical"
                                                                    name="umbilical"
                                                                    disabled={true}
                                                                    value={listPhysicalExam.abdomen.abdomenPalpation.umbilical}
                                                                    onChange={handleChangePE} >
                                                                    <option class="" value="">{" Select options"}</option>
                                                                    <option class="py-1" value="Soft">Soft</option>
                                                                    <option class="py-1" value="Tender">Tender</option>
                                                                </select>
                                                            </div>
                                                            <hr class=" bg-gray-secondary" />
                                                        </div>
                                                    </div>
                                                    <div class="w-11/12 mb-4">
                                                        <div class="flex space-x-3">
                                                            <p class="text-xs font-rubik font-medium text-gray-primary">
                                                                Left Lumbar (LL)
                                                            </p>
                                                        </div>
                                                        <div class="pt-4">
                                                            <div class="flex justify-between h-3  h-full">
                                                                <select
                                                                    className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                                    id="leftLumbar"
                                                                    name="leftLumbar"
                                                                    disabled={true}
                                                                    value={listPhysicalExam.abdomen.abdomenPalpation.leftLumbar}
                                                                    onChange={handleChangePE} >
                                                                    <option class="" value="">{" Select options"}</option>
                                                                    <option class="py-1" value="Soft">Soft</option>
                                                                    <option class="py-1" value="Tender">Tender</option>
                                                                </select>
                                                            </div>
                                                            <hr class=" bg-gray-secondary" />
                                                        </div>
                                                    </div>
                                                    <div class="w-11/12 mb-4">
                                                        <div class="flex space-x-3">
                                                            <p class="text-xs font-rubik font-medium text-gray-primary">
                                                                Right Iliac (RI)
                                                            </p>
                                                        </div>
                                                        <div class="pt-4">
                                                            <div class="flex justify-between h-3  h-full">
                                                                <select
                                                                    className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                                    id="rightLliac"
                                                                    name="rightLliac"
                                                                    disabled={true}
                                                                    value={listPhysicalExam.abdomen.abdomenPalpation.rightLliac}
                                                                    onChange={handleChangePE} >
                                                                    <option class="" value="">{" Select options"}</option>
                                                                    <option class="py-1" value="Soft">Soft</option>
                                                                    <option class="py-1" value="Tender">Tender</option>
                                                                </select>
                                                            </div>
                                                            <hr class=" bg-gray-secondary" />
                                                        </div>
                                                    </div>



                                                    <div class="w-11/12 mb-4">
                                                        <div class="flex space-x-3">
                                                            <p class="text-xs font-rubik font-medium text-gray-primary">
                                                                Hypogastric (H)
                                                            </p>
                                                        </div>
                                                        <div class="pt-4">
                                                            <div class="flex justify-between h-3  h-full">
                                                                <select
                                                                    className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                                    id="hypogastric"
                                                                    name="hypogastric"
                                                                    disabled={true}
                                                                    value={listPhysicalExam.abdomen.abdomenPalpation.hypogastric}
                                                                    onChange={handleChangePE} >
                                                                    <option class="" value="">{" Select options"}</option>
                                                                    <option class="py-1" value="Soft">Soft</option>
                                                                    <option class="py-1" value="Tender">Tender</option>
                                                                </select>
                                                            </div>
                                                            <hr class=" bg-gray-secondary" />
                                                        </div>
                                                    </div>


                                                    <div class="w-11/12 mb-4">
                                                        <div class="flex space-x-3">
                                                            <p class="text-xs font-rubik font-medium text-gray-primary">
                                                                Left Iliac (LI)
                                                            </p>
                                                        </div>
                                                        <div class="pt-4">
                                                            <div class="flex justify-between h-3  h-full">
                                                                <select
                                                                    className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                                    id="leftLliac "
                                                                    name="leftLliac"
                                                                    disabled={true}
                                                                    value={listPhysicalExam.abdomen.abdomenPalpation.leftLliac}
                                                                    onChange={handleChangePE} >
                                                                    <option class="" value="">{" Select options"}</option>
                                                                    <option class="py-1" value="Soft">Soft</option>
                                                                    <option class="py-1" value="Tender">Tender</option>
                                                                </select>
                                                            </div>
                                                            <hr class=" bg-gray-secondary" />
                                                        </div>
                                                    </div>
                                                    <div class="w-11/12 mb-4">
                                                        <div class="flex space-x-3">
                                                            <p class="text-xs font-rubik font-medium text-gray-primary">
                                                                Enlarged organs ( if any )
                                                            </p>
                                                        </div>
                                                        <div class="pt-4">
                                                            <div class="flex justify-between h-3  h-full">
                                                                <select
                                                                    className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                                    id="enlOrgan"
                                                                    name="enlOrgan"
                                                                    disabled={true}
                                                                    value={listPhysicalExam.abdomen.abdomenPalpation.enlOrgan}
                                                                    onChange={handleChangePE} >
                                                                    <option class="" value="">{" Select options"}</option>
                                                                    <option class="py-1" value="Yes">Yes</option>
                                                                    <option class="py-1" value="No">No</option>
                                                                </select>
                                                            </div>
                                                            <hr class=" bg-gray-secondary" />
                                                        </div>
                                                    </div>
                                                    {listPhysicalExam.abdomen.abdomenPalpation.enlOrganDesc &&
                                                        <div class="w-11/12 mb-4">
                                                            <div class="flex space-x-3">
                                                                <p class="text-xs font-rubik font-medium text-gray-primary">
                                                                    Enlarged organs description
                                                                </p>
                                                            </div>
                                                            <div class="pt-4">
                                                                <div class="flex justify-between h-3  h-full">
                                                                    <input
                                                                        id="enlOrganDesc"
                                                                        name="enlOrganDesc"
                                                                        type="text"
                                                                        editable={false}
                                                                        value={listPhysicalExam.abdomen.abdomenPalpation.enlOrganDesc}
                                                                        onChange={handleChangePE}
                                                                        class="peer h-10 w-full border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                                        placeholder="Enter Enlarged organs"
                                                                    />
                                                                </div>
                                                                <hr class=" bg-gray-secondary" />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div>
                                                <img src={Abdomen} />
                                            </div>
                                        </div>
                                        <div class="flex justify-between my-3">
                                            <p class="text-lg font-small text-brand-secondary">Cardiovascular system</p>
                                        </div>
                                        <div class="flex justify-between my-3 mb-3">
                                            <p class="text-lg font-small text-black">Cardiac Auscultation</p>
                                        </div>
                                        <div class="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                                            <div class="w-11/12 mb-4">
                                                <div class="flex space-x-3">
                                                    <p class="text-xs font-rubik font-medium text-gray-primary">
                                                        S1 (1st heart sound)
                                                    </p>
                                                </div>
                                                <div class="pt-4">
                                                    <div class="flex justify-between h-3  h-full">
                                                        <select
                                                            className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                            id="cardiacAuscultationS1"
                                                            name="cardiacAuscultationS1"
                                                            disabled={true}
                                                            value={listPhysicalExam.cardiacAuscultationS1}
                                                            onChange={handleChangePE} >
                                                            <option class="" value="">{" Select options"}</option>
                                                            <option class="py-1" value="Normal">Normal</option>
                                                            <option class="py-1" value="Abnormal">Abnormal</option>
                                                        </select>
                                                    </div>
                                                    <hr class=" bg-gray-secondary" />
                                                </div>
                                            </div>
                                            <div class="w-11/12 mb-4">
                                                <div class="flex space-x-3">
                                                    <p class="text-xs font-rubik font-medium text-gray-primary">
                                                        S2 (2nd heart sound)
                                                    </p>
                                                </div>
                                                <div class="pt-4">
                                                    <div class="flex justify-between h-3  h-full">
                                                        <select
                                                            className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                            id="cardiacAuscultationS2"
                                                            name="cardiacAuscultationS2"
                                                            disabled={true}
                                                            value={listPhysicalExam.cardiacAuscultationS2}
                                                            onChange={handleChangePE} >
                                                            <option class="" value="">{" Select options"}</option>
                                                            <option class="py-1" value="Normal">Normal</option>
                                                            <option class="py-1" value="Abnormal">Abnormal</option>
                                                        </select>
                                                    </div>
                                                    <hr class=" bg-gray-secondary" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex justify-between my-3">
                                            <p class="text-lg font-small text-brand-secondary">Central Nervous System (CNS)</p>
                                        </div>
                                        <div class="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                                            <div class="w-11/12 mb-4">
                                                <div class="flex space-x-3">
                                                    <p class="text-xs font-rubik font-medium text-gray-primary">
                                                        Orientation / Mood of the patient(on Observation)
                                                    </p>
                                                </div>
                                                <div class="pt-4">
                                                    <div class="flex justify-between h-3  h-full">
                                                        <select
                                                            className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                            id="mood"
                                                            name="mood"
                                                            disabled={true}
                                                            value={listPhysicalExam.mood}
                                                            onChange={handleChangePE} >
                                                            <option class="" value="">{" Select options"}</option>
                                                            <option class="py-1" value="Normal">Normal</option>
                                                            <option class="py-1" value="Abnormal">Abnormal</option>
                                                        </select>
                                                    </div>
                                                    <hr class=" bg-gray-secondary" />
                                                </div>
                                            </div>
                                            {listPhysicalExam.moodDesc &&
                                                <div class="w-11/12 mb-4">
                                                    <div class="flex space-x-3">
                                                        <p class="text-xs font-rubik font-medium text-gray-primary">
                                                            Enter Orientation / Mood of the patient (on Observation)
                                                        </p>
                                                    </div>
                                                    <div class="pt-4">
                                                        <div class="flex justify-between h-3  h-full">
                                                            <input
                                                                id="moodDesc"
                                                                name="moodDesc"
                                                                type="text"
                                                                editable={false}
                                                                value={listPhysicalExam.moodDesc}
                                                                onChange={handleChangePE}
                                                                class="peer h-10 w-full border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                                placeholder="Enter Orientation"
                                                            />
                                                        </div>
                                                        <hr class=" bg-gray-secondary" />
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                        <div class="h-10 my-4">

                                        </div>
                                    </div>
                                }
                                {!listPhysicalExam &&
                                    <div class="rounded-lg shadow-lg bg-white-600 w-full h-112 p-5 antialiased justify-between border border-gray-200 mt-2 lg:pb-4 mb-10">
                                        <div class=" w-full align-center text-center ">
                                            <p className="text-center item-center mt-40 mb-40   ">No data available</p>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

{/* 
                {showFilter && (
                    <Dialog
                        header="Physical Examination"
                        visible={showFilter}
                        modal={false}
                        style={{ width: "80vw" }}
                        onHide={() => setShowFilter(false)}
                    >
                        <div className='block mx-0 lg:mx-12 md:mx-6 sm:mx-4 sm:my-2'>
                            <AddPatientProfilePEPopup close={closePopup} />
                        </div>
                    </Dialog>
                )} */}
            </div>
        </>
    );
}

export default ViewPEPopup;