import React, { useEffect, useReducer, useState } from "react";
import Signature from "../Assets/Images/signature.webp";
import Stamp from "../Assets/Images/stamp.webp";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPostConsultation } from "../Redux/Actions/patientAction";
import moment from "moment";
import { APP_ROUTES } from "../application/Router/constants/AppRoutes";
import { CircularProgress } from "@material-ui/core";
import { IMG_URL } from "../config/constant";
import newLogo from "../Assets/Images/newLogo.png";
import PatientService from "../Redux/services/patientService";
import { jsPDF } from "jspdf";
import rximg from "../Assets/Images/rx.png";

function ConsulatationReport(props) {
  //const { postConsultation, isLoading } = useSelector(state => state.doctorconsultationlist);
  const [postConsultation, setpostConsultation] = useState({});
  //const { patientLabTestsList, patientDrugPrescriptionList } = postConsultation;
  const [patientLabTestsList, setpatientLabTestsList] = useState(null);
  const [patientDrugPrescriptionList, setpatientDrugPrescriptionList] =
    useState(null);
  const [labTestList, setLabTestList] = useState([]);
  const [stamp, setStamp] = useState([]);
  const [signature, setSignature] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const { state } = location;
  const dispatch = useDispatch();
  const [reading1RE, setReReading1] = useState();
  const [reading2RE, setReReading2] = useState();
  const [reading3RE, setReReading3] = useState();
  const [readingFinalRE, setReReadingFinal] = useState();
  const [reading1LE, setLeReading1] = useState();
  const [reading2LE, setLeReading2] = useState();
  const [reading3LE, setLeReading3] = useState();
  const [readingFinalLE, setLeReadingFinal] = useState();
  const [eyeWearUser, setEyeWearUser] = useState(false);
  const redirectTo = (event, location) => {
    event.preventDefault();
    history.push(location);
  };
  console.log(postConsultation, "POSTCONSULT");
  useEffect(() => {
    if (props && props.data) {
      setpostConsultation(props.data);
      props.data.patientLabTestsList =
        props.data?.patientLabTestsList &&
        props.data?.patientLabTestsList.filter((x) => x.status == 1);
      props.data.patientDrugPrescriptionList =
        props.data?.patientDrugPrescriptionList &&
        props.data?.patientDrugPrescriptionList.filter((x) => x.status == 1);
      setpatientLabTestsList(props.data.patientLabTestsList);
      setpatientDrugPrescriptionList(props.data.patientDrugPrescriptionList);
      if (props.eyeWearTable) {
        setEyeWearUser(true);
        const leftEyeData =
          props.data.eyeReadingMain && props.data.eyeReadingMain.leftEye;
        const rightEyeData =
          props.data.eyeReadingMain && props.data.eyeReadingMain.rightEye;
        setLeReadingFinal(leftEyeData.finalReading);
        setLeReading1(leftEyeData.readingList[0]);
        setLeReading2(leftEyeData.readingList[1]);
        setLeReading3(leftEyeData.readingList[2]);

        setReReadingFinal(rightEyeData.finalReading);
        setReReading1(rightEyeData.readingList[0]);
        setReReading2(rightEyeData.readingList[1]);
        setReReading3(rightEyeData.readingList[2]);
      }
    } else {
      let payload = state?.id ? state.id : state;
      PatientService.getPostConsultation(payload).then((res) => {
        let symptoms = [];
        if (res.data[0] && res.data[0].symptomsName) {
          symptoms = res.data[0].symptomsName.split(",");
          res.data[0].symptomsList = symptoms;
        }
        if (res.data && res.data.length) {
          setpostConsultation(res.data[0]);
        }
        if (res.data[0] && res.data[0].patientLabTestsList) {
          setpatientLabTestsList(res.data[0].patientLabTestsList);
        }
        if (res.data[0] && res.data[0].patientDrugPrescriptionList) {
          setpatientDrugPrescriptionList(
            res.data[0].patientDrugPrescriptionList
          );
        }
        //eyewear_data
        if (res.data[0] && res.data[0].eyeReadingMain != null) {
          setEyeWearUser(true);
          const leftEyeData =
            res.data[0].eyeReadingMain && res.data[0].eyeReadingMain.leftEye;
          const rightEyeData =
            res.data[0].eyeReadingMain && res.data[0].eyeReadingMain.rightEye;
          setLeReadingFinal(leftEyeData.finalReading);
          setLeReading1(leftEyeData.readingList[0]);
          setLeReading2(leftEyeData.readingList[1]);
          setLeReading3(leftEyeData.readingList[2]);

          setReReadingFinal(rightEyeData.finalReading);
          setReReading1(rightEyeData.readingList[0]);
          setReReading2(rightEyeData.readingList[1]);
          setReReading3(rightEyeData.readingList[2]);
        }
        // dispatch(getPostConsultation(payload)).then((res) => {
        //     console.log(res);
      });

      //postConsultation = state[0];
      // let payload = (state?.id) ? state.id : state;
      // dispatch(getPostConsultation(payload)).then((res) => {
      //     console.log(res);
      //    });
    }
  }, []);

  useEffect(() => {
    if (postConsultation?.patientName || props?.patient) {
      console.log(postConsultation);
      PatientService.getBase64Img(
        postConsultation?.userSignature || props?.patient?.userSignature
      ).then(
        (res) => {
          console.log(res);
          if (res.data) {
            setSignature("data:image/jpg;base64," + res.data.fileData);
            console.log(signature);
          }
        },
        (err) => {
          console.log(err);
        }
      );

      PatientService.getBase64Img(
        postConsultation?.hospitalStamp || props?.patient?.hospitalStamp
      ).then(
        (res) => {
          console.log(res);
          if (res.data) {
            setStamp("data:image/jpg;base64," + res.data.fileData);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }, [postConsultation?.patientName, props?.patient]);

  useEffect(() => {
    if (patientLabTestsList) {
      const labData = patientLabTestsList;
      const unique = [...new Set(labData.map((item) => item.labParentName))];
      const labArray = [];
      for (let s = 0; s < unique.length; s++) {
        const labFilter = labData.filter((x) => x.labParentName == unique[s]);
        const payload = {
          parent: unique[s],
          data: labFilter,
        };
        labArray.push(payload);
      }

      setLabTestList(labArray);
    }
  }, [patientLabTestsList]);

  // if (isLoading) {
  //     return <div className="flex justify-center items-center" style={{ height: "50vh" }}>
  //         <CircularProgress />
  //     </div>
  // }
  console.log(patientLabTestsList, "sdkbfskdjfbskdgksd");

  const sympt = () => { };

  const downloadImageOrPdf = () => {
    PatientService.imagetoData(
      postConsultation.patientDocumentList[0].docName
    ).then((res) => {
      var base64;
      if (postConsultation.patientDocumentList[0].documentType == "pdf") {
        base64 = "data:application/pdf;base64,";
      } else {
        base64 = "data:image/png;base64,";
      }
      //alert(JSON.stringify(res.data.fileData))
      var FileSaver = require("file-saver");
      FileSaver.saveAs(
        base64 + res.data.fileData,
        postConsultation.patientDocumentList[0].docName
      );
    });
    // var FileSaver = require("file-saver");
    // FileSaver.saveAs(
    //   IMG_URL + postConsultation.patientDocumentList[0].docName,
    //   postConsultation.patientDocumentList[0].docName
    // );
  };

  const download = () => {
    const input = document.getElementById("abc");
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "px",
      format: "A4",
      userUnit: "px",
    });
    pdf
      .html(input, {
        html2canvas: { scale: 0.38, allowTaint: false },
        margin: [1, 0, 1, 0],
        autoPaging: true,
      })
      .then(() => {
        const fileName =
          "e-Prescription-" + postConsultation?.patientName + ".pdf";
        console.log(fileName);
        pdf.save(fileName);
        // history.goBack();
      });
  };
  const calage = (dateString) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  return (
    <>
      {postConsultation &&
        postConsultation?.patientDocumentList &&
        postConsultation?.patientDocumentList[0]?.docName.split(".")[1] !=
        "string" ? (
        <div>
          <div className="flex justify-end">
            {postConsultation.patientDocumentList[0].documentType ==
              "pdf" ? null : (
              <button
                onClick={() => downloadImageOrPdf()}
                className="bg-transparent text-base rounded-lg  m-1 mr-5 w-44 float-right text-brand-secondary  font-medium  py-2 px-12 border border-brand-secondary "
              >
                Download{" "}
              </button>
            )}
            {!state.hidesubmit && (
              <button
                onClick={() => history.push(APP_ROUTES.DASHBOARD)}
                className="bg-brand-secondary rounded-lg m-1 mr-5  float-right text-white font-medium py-2 px-10 rounded text-base w-44"
              >
                Submit
              </button>
            )}
          </div>
          {postConsultation.patientDocumentList[0].documentType == "pdf" ? (
            <div style={{ height: "100vh" }}>
              <object
                data={IMG_URL + postConsultation.patientDocumentList[0].docName}
                type="application/pdf"
                width="100%"
                height="100%"
              >
                <p>
                  Alternative text - include a link{" "}
                  <a
                    href={
                      IMG_URL + postConsultation.patientDocumentList[0].docName
                    }
                  >
                    to the PDF!
                  </a>
                </p>
              </object>
            </div>
          ) : (
            <img
              src={IMG_URL + postConsultation.patientDocumentList[0].docName}
              className="ml-auto mr-auto w-auto"
              style={{ height: "80vh" }}
            />
          )}
        </div>
      ) : (
        <>
          {" "}
          <div
            id="abc"
            className="p-5 bg-white-100 mt-10 mb-0 mx-4 w-90 justify-center  border dark:border-slate-700"
          >
            {/* <span>{JSON.stringify(postConsultation)}</span> */}
            <div className="px-2">
              <div className="flex justify-between mb-4  ml-1">
                <div>
                  {/* <img
                    
                    alt="logo"
                    src={newLogo}
                    width={230}
                  /> */}
                </div>
                <div>
                  {/* <p className="font-bold">
                    <h1 className="text-2xl font-bold ">
                      {locationObj.hospitalName}
                    </h1>
                    <h6>{locationObj.locationAddress1}</h6>
                    <h6>{locationObj.locationAddress2}</h6>
                    <h6>
                      {locationObj.locationCity +
                        " " +
                        locationObj.locationPinCode}
                    </h6>
                    <h6>Ph NO : {locationObj.locationContactNumber}</h6>
                    <h6>Email : {locationObj.locationEmail}</h6>
                  </p> */}
                </div>
              </div>

              <div className="lg:flex flex flex-col">
                {props?.patient ? (
                  <h6 className="font-bold ">
                    {props?.patient.userSalutation} {props?.patient.userName}
                  </h6>
                ) : (
                  <h6 className="font-bold ">
                    {postConsultation?.userSalutation}{" "}
                    {postConsultation?.userName}
                  </h6>
                )}

                {props?.patient ? (
                  <h6 className="font-bold ">
                    {props.patient?.userQualification}
                  </h6>
                ) : (
                  <h6 className="font-bold ">
                    {postConsultation?.userQualification}
                  </h6>
                )}

                {props?.patient ? (
                  <h6 className="font-bold ">
                    {props?.patient.userSpecialityDept}
                  </h6>
                ) : (
                  <h6 className="font-bold ">
                    {postConsultation?.userSpecialityDept}
                  </h6>
                )}

                {props?.patient ? (
                  <h6 className="font-bold ">
                    Regn No:{props?.patient.userMCIVerification}
                  </h6>
                ) : (
                  <h6 className="font-bold ">
                    Regn No:{postConsultation?.userMCIVerification}
                  </h6>
                )}

                <hr className="hr-line" />
              </div>

              <div className="grid grid-cols-6 mb-5">
                <div className="border dark:border-slate-700 px-3 py-1 font-bold">
                  Name:
                </div>
                {props?.patient ? (
                  <div className="border dark:border-slate-700 px-3 py-1">
                    {props?.patient.patientsalutation +
                      props?.patient.patientName}
                  </div>
                ) : (
                  <div className="border dark:border-slate-700 px-3 py-1">
                    {postConsultation?.patientsalutation +
                      postConsultation?.patientName}
                  </div>
                )}

                <div className="border dark:border-slate-700 px-3 py-1 font-bold">
                  Age/Sex:
                </div>
                {props?.patient ? (
                  <div className="border dark:border-slate-700 px-3 py-1">
                    {calage(props?.patient.patientDOB) +
                      " / " +
                      (props?.patient.patientgender === "M"
                        ? "Male" :
                        props?.patient.patientgender === "F"
                          ? "Female"
                          : "Others")}
                  </div>
                ) : (
                  <div className="border dark:border-slate-700 px-3 py-1">
                    {postConsultation?.patientAge +
                      " / " +
                      (postConsultation?.patientgender === "M"
                        ? "Male":
                        postConsultation?.patientgender === "F"
                        ? "Female"
                        : "Others")}
                  </div>
                )}

                <div className="border dark:border-slate-700 px-3 py-1 font-bold">
                  Hospital:
                </div>
                {props?.patient ? (
                  <div className="border dark:border-slate-700 px-3 py-1">
                    {props?.patient.hospitalName}
                  </div>
                ) : (
                  <div className="border dark:border-slate-700 px-3 py-1">
                    {postConsultation?.patientHospitalName}
                  </div>
                )}

                <div className="border dark:border-slate-700 px-3 py-1 font-bold">
                  Phone Number:
                </div>
                {props?.patient ? (
                  <div className="border dark:border-slate-700 px-3 py-1">
                    {props?.patient.patientMobileNo}
                  </div>
                ) : (
                  <div className="border dark:border-slate-700 px-3 py-1">
                    {postConsultation?.patientMobileNo}
                  </div>
                )}

                <div className="border dark:border-slate-700 px-3 py-1 font-bold">
                  Visit Type:
                </div>
                <div className="border dark:border-slate-700 px-3 py-1">
                  {postConsultation?.consultationType === "Q"
                    ? "Quick Consultation"
                    : postConsultation?.consultationType === "V"
                      ? "Video Consultation"
                      : postConsultation?.consultationType === "IC"
                        ? "In-Clinic Consultation"
                        : postConsultation?.consultationType === "I"
                          ? "In-person Consultation"
                          : postConsultation?.consultationType === "A"
                            ? "Audio Consultation"
                            : "Quick Consultation"}
                </div>

                <div className="border dark:border-slate-700 px-3 py-1 font-bold">
                  Visit Date:
                </div>
                <div className="border dark:border-slate-700 px-3 py-1" v>
                  {moment(postConsultation?.visitDate).format("DD/MM/yyyy")}
                </div>
              </div>

              <img src={rximg} style={{ width: "25px" }} />

              {props?.patient ? (
                <div className="grid grid-cols-1 my-2">
                  <p>
                    <b className="underline underline-offset-1">
                      Chief Complaint:
                    </b>{" "}
                    {props?.patient?.consultationsReason}
                  </p>
                </div>
              ) : (
                <div className="grid grid-cols-1 my-2">
                  <p>
                    <b className="underline underline-offset-1">
                      Chief Complaint:
                    </b>{" "}
                    {postConsultation?.consultReason}
                  </p>
                </div>
              )}

              {/* {postConsultation?.consultReason &&
              <div className="grid grid-cols-1 my-2">
                <p>
                  <b className="underline underline-offset-1">
                    Chief Complaint:
                  </b>{" "}
                  {postConsultation?.consultReason}
                </p>
              </div>
            } */}
              {(postConsultation?.symptomsList ||
                postConsultation?.symptomsList) && (
                  <div className="mb-5">
                    <p className="underline underline-offset-1">
                      <b className="underline underline-offset-1">Findings :</b>{" "}
                    </p>
                    <div className="flex items-center">
                      {postConsultation?.symptomsList &&
                        postConsultation?.symptomsList.map((res, i) => (
                          <div className="column-container ml-2">
                            <p className="text-base font-montserrat  font-thin ">
                              {i === postConsultation.symptomsList.length - 1
                                ? `${res}.`
                                : `${res},`}
                            </p>
                          </div>
                        ))}
                    </div>
                  </div>
                )}

              {(postConsultation?.primarySymptoms ||
                postConsultation?.primaryDiagnosis) && (
                  <div className="grid grid-cols-1 my-2">
                    <p className="">
                      <b className="underline underline-offset-1">
                        Primary Diagnosis :
                      </b>{" "}
                    </p>
                    <p className="text-base font-montserrat  font-thin ">
                      ICD-10 : {postConsultation?.primarySymptoms}{" "}
                    </p>
                    <p className="text-base font-montserrat  font-thin ">
                      Description : {postConsultation?.primaryDiagnosis}{" "}
                    </p>
                  </div>
                )}
              {(postConsultation?.secondarySymptoms ||
                postConsultation?.secondaryDiagnosis) && (
                  <div className="grid grid-cols-1 my-2">
                    <p className="">
                      <b className="underline underline-offset-1">
                        Secondary Diagnosis :
                      </b>{" "}
                    </p>
                    <p className="text-base font-montserrat  font-thin ">
                      ICD-10 : {postConsultation?.secondarySymptoms}{" "}
                    </p>
                    <p className="text-base font-montserrat  font-thin ">
                      Description : {postConsultation?.secondaryDiagnosis}{" "}
                    </p>
                  </div>
                )}
              {postConsultation?.symptoms && (
                <div className="mb-5">
                  <p className="underline underline-offset-1">
                    <b className="underline underline-offset-1">Symptoms :</b>{" "}
                  </p>
                  <p className="text-base font-montserrat  font-thin ">
                    {postConsultation?.symptoms}
                  </p>
                </div>
              )}
              {/* <p className="text-base text-gray-primary font-medium font-rubik">History of Presenting Illness:</p> */}

              {postConsultation.site ||
                postConsultation.onset ||
                postConsultation.radiation ||
                postConsultation.characteristics ||
                postConsultation.associatedSymptoms ||
                postConsultation?.timing ||
                postConsultation?.exacerbatingFactors ||
                postConsultation?.relievingFactors ||
                postConsultation.treatmentTaken ||
                // postConsultation.comments ||
                postConsultation.severity ? (
                <p className="underline underline-offset-1">
                  <b className="underline underline-offset-1">
                    History of Presenting Illness:
                  </b>{" "}
                </p>
              ) : null}
              <div className="grid grid-cols-2 gap-5">
                {postConsultation?.site && (
                  <div className="mb-5">
                    <p className="underline underline-offset-1">Site: </p>
                    <p className="text-base font-montserrat  font-thin ">
                      {postConsultation?.site}
                    </p>
                  </div>
                )}
                {postConsultation?.onset && (
                  <div className="mb-5">
                    <p className="underline underline-offset-1">Onset: </p>
                    <p className="text-base font-montserrat  font-thin ">
                      {postConsultation?.onset}
                    </p>
                  </div>
                )}

                {postConsultation?.characteristics && (
                  <div className="mb-5">
                    <p className="underline underline-offset-1">Character: </p>
                    <p className="text-base font-montserrat  font-thin ">
                      {postConsultation?.characteristics}
                    </p>
                  </div>
                )}
                {postConsultation?.radiation && (
                  <div className="mb-5">
                    <p className="underline underline-offset-1">Radiation: </p>
                    <p className="text-base font-montserrat  font-thin ">
                      {postConsultation?.radiation}
                    </p>
                  </div>
                )}

                {postConsultation?.associatedSymptoms && (
                  <div className="mb-5">
                    <p className="underline underline-offset-1">
                      Associated Symptoms:{" "}
                    </p>
                    <p className="text-base font-montserrat  font-thin ">
                      {postConsultation?.associatedSymptoms}
                    </p>
                  </div>
                )}

                {postConsultation?.timing && (
                  <div className="mb-5">
                    <p className="underline underline-offset-1">Timing: </p>
                    <p className="text-base font-montserrat  font-thin ">
                      {postConsultation?.timing}
                    </p>
                  </div>
                )}
                {postConsultation?.exacerbatingFactors && (
                  <div className="mb-5">
                    <p className="underline underline-offset-1">
                      Exacerbating Factors:{" "}
                    </p>
                    <p className="text-base font-montserrat  font-thin ">
                      {postConsultation?.exacerbatingFactors}
                    </p>
                  </div>
                )}
                {postConsultation?.relievingFactors && (
                  <div className="mb-5">
                    <p className="underline underline-offset-1">
                      Relieving Factors:{" "}
                    </p>
                    <p className="text-base font-montserrat  font-thin ">
                      {postConsultation?.relievingFactors}
                    </p>
                  </div>
                )}
                {postConsultation?.severity && (
                  <div className="mb-5">
                    <p className="underline underline-offset-1">Severity: </p>
                    <p className="text-base font-montserrat  font-thin ">
                      {postConsultation?.severity}
                    </p>
                  </div>
                )}
                {postConsultation?.treatmentTaken && (
                  <div className="mb-5">
                    <p className="underline underline-offset-1">
                      Any Treatment Taken? :{" "}
                    </p>
                    <p className="text-base font-montserrat  font-thin ">
                      {postConsultation?.treatmentTaken}
                    </p>
                  </div>
                )}
                {postConsultation?.comments && (
                  <div className="mb-5">
                    <p className="underline underline-offset-1">Comments: </p>
                    <p className="text-base font-montserrat  font-thin ">
                      {postConsultation?.comments}
                    </p>
                  </div>
                )}
              </div>
              {/* <div className="mb-5">
              <p className="underline underline-offset-1">
                <b className="underline underline-offset-1">Doctors Findings :</b>{" "}
              </p>
              {postConsultation?.symptomsList && postConsultation?.symptomsList.map((res, i) => (

                <p className="text-base font-montserrat  font-thin ">
                  {res},
                </p>
              ))}
            </div> */}
              {postConsultation?.patientVitalList &&
                postConsultation?.patientVitalList.length && (
                  <>
                    <div className="grid grid-cols-1 my-2">
                      <p>
                        <b className="underline underline-offset-1">Vitals :</b>{" "}
                        {postConsultation?.patientVitalList[0].height && (
                          <>
                            {" "}
                            Height -{" "}
                            {
                              postConsultation?.patientVitalList[0].height
                            } CM{" "}
                          </>
                        )}{" "}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {postConsultation?.patientVitalList[0].weight && (
                          <>
                            {" "}
                            Weight -{" "}
                            {
                              postConsultation?.patientVitalList[0].weight
                            } KG{" "}
                          </>
                        )}{" "}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {postConsultation?.patientVitalList[0].bmi && (
                          <>
                            {" "}
                            BMI - {
                              postConsultation?.patientVitalList[0].bmi
                            }{" "}
                            KG/M2{" "}
                          </>
                        )}{" "}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {postConsultation?.patientVitalList[0].systolic && (
                          <>
                            {" "}
                            Blood Pressure (SYS) &nbsp;&nbsp; -{" "}
                            {
                              postConsultation?.patientVitalList[0].systolic
                            }{" "}
                            mmHg{" "}
                          </>
                        )}{" "}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {postConsultation?.patientVitalList[0].diastolic && (
                          <>
                            {" "}
                            Blood Pressure (DIA) -{" "}
                            {
                              postConsultation?.patientVitalList[0].diastolic
                            }{" "}
                            mmHg{" "}
                          </>
                        )}{" "}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {postConsultation?.patientVitalList[0].spo2 && (
                          <>
                            {" "}
                            Pulse Ox. -{" "}
                            {postConsultation?.patientVitalList[0].spo2} %{" "}
                          </>
                        )}{" "}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {postConsultation?.patientVitalList[0].heartRate && (
                          <>
                            {" "}
                            Heart Rate -{" "}
                            {
                              postConsultation?.patientVitalList[0].heartRate
                            }{" "}
                            Beats/min{" "}
                          </>
                        )}{" "}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {postConsultation?.patientVitalList[0]
                          .respirationRate && (
                            <>
                              {" "}
                              Respiration Rate -{" "}
                              {
                                postConsultation?.patientVitalList[0]
                                  .respirationRate
                              }{" "}
                              Breaths/min{" "}
                            </>
                          )}{" "}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {postConsultation?.patientVitalList[0].temperature && (
                          <>
                            {" "}
                            Temperature -{" "}
                            {
                              postConsultation?.patientVitalList[0].temperature
                            }{" "}
                            °F{" "}
                          </>
                        )}{" "}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </p>
                    </div>
                  </>
                )}
              <div className="mb-2">
                {patientDrugPrescriptionList &&
                  patientDrugPrescriptionList.length > 0 && (
                    <>
                      <p className="  mb-2 underline underline-offset-1">
                        <b className="underline underline-offset-1">
                          Medicines Prescribed :
                        </b>{" "}
                      </p>

                      <table className="table-auto w-full mb-5">
                        <thead>
                          <tr>
                            <th className="border dark:border-slate-700 px-3 py-2 mb-2 text-left"></th>
                            <th className="border dark:border-slate-700 px-3 py-2 mb-2 text-left">
                              Medication
                            </th>
                            <th className="border dark:border-slate-700 px-3 py-2 mb-2 text-left">
                              Dosage
                            </th>
                            <th className="border dark:border-slate-700 px-3 py-2 mb-2 text-left">
                              Qty
                            </th>
                            <th className="border dark:border-slate-700 px-3 py-2 mb-2 text-left">
                              Notes
                            </th>
                            <th className="border dark:border-slate-700 px-3 py-2 mb-2 text-left">
                              Instruction
                            </th>
                            {/* <th className="border-2 border-inherit">Periodically</th> */}
                            <th className="border dark:border-slate-700 px-3 py-2 mb-2 text-left">
                              Duration
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {patientDrugPrescriptionList &&
                            patientDrugPrescriptionList.length > 0 &&
                            patientDrugPrescriptionList.map((res, i) => (
                              <>
                                <tr>
                                  <td className="border dark:border-slate-700 px-3 py-1">
                                    {i + 1}
                                  </td>
                                  <td className="border dark:border-slate-700 px-3 py-1">
                                    {res?.drugName}
                                    <br></br>
                                    <span className="text-xs">
                                      {res?.composition}
                                    </span>
                                  </td>
                                  <td className="border dark:border-slate-700 px-3 py-1">
                                    {res?.dosage}
                                  </td>
                                  <td className="border dark:border-slate-700 px-3 py-1">
                                    {res?.quantity}
                                  </td>
                                  <td className="border dark:border-slate-700 px-3 py-1">
                                    {res?.notes}
                                  </td>
                                  <td className="border dark:border-slate-700 px-3 py-1">
                                    {res.instruction === "0"
                                      ? "After Meal"
                                      : "Before Meal"}
                                  </td>
                                  <td className="border dark:border-slate-700 px-3 py-1">
                                    {" "}
                                    {res?.duration} days
                                  </td>
                                </tr>
                              </>
                            ))}
                        </tbody>
                      </table>
                    </>
                  )}
                <div className="mt-2  mb-5">
                  {labTestList && labTestList.length > 0 && (
                    <p className="  underline underline-offset-1  mb-2">
                      <b className="underline underline-offset-1">
                        Lab Test Prescribed :
                      </b>{" "}
                    </p>
                  )}
                  {labTestList &&
                    labTestList.length > 0 &&
                    labTestList.slice(0, 1).map((labres, i) => (
                      <div>
                        <div className="grid grid-cols-2  justify-start ">
                          <div className="text-md font-bold font-rubik text-left border dark:border-slate-700 px-3 py-1">
                            {labres.parent}
                          </div>
                          <div className="text-md font-bold font-rubik  text-left border dark:border-slate-700 px-3 py-1">
                            Test Type
                          </div>
                        </div>
                        {labres &&
                          labres.data.length > 0 &&
                          labres.data.map((res, i) => (
                            <div
                              className="grid grid-cols-2 justify-start flex justify-between"
                              key={i}
                            >
                              <div className="text-sm  border dark:border-slate-700 px-3 py-1">
                                {res?.labTestDescription
                                  ? res?.labTestDescription
                                  : res?.labTestName}
                              </div>
                              <div className="text-sm  text-left border dark:border-slate-700 px-3 py-1">
                                Pathology
                              </div>
                            </div>
                          ))}
                      </div>
                    ))}
                </div>
              </div>
              {eyeWearUser && (
                <div className="border border-gray-200 my-3 rounded-lg p-5 mt-10">
                  <div className="flex">
                    <div className="newtable-list">
                      <ul className="font-bold text-base">
                        <li>Rd1</li>
                        <li>Rd2</li>
                        <li>Rd3</li>
                        <li>Final</li>
                      </ul>
                    </div>
                    <div className="">
                      <div
                        className="w-full rounded-lg flex justify-evenly items-center p-3"
                        style={{ background: "#EFFAFF" }}
                      >
                        <h6 className="text-brand-primary font-Rubik text-lg font-semibold">
                          Right Eye
                        </h6>
                        <div
                          className="border-l h-6"
                          style={{
                            borderColor: "rgba(0, 93, 141, 0.34)",
                          }}
                        ></div>
                        <h6 className="text-brand-primary font-Rubik text-lg font-semibold">
                          Left Eye
                        </h6>
                      </div>
                      <div className="grid grid-cols-2 gap-8 mt-5">
                        <div className="border border-gray-200  rounded-lg pb-3">
                          <table className="newtable">
                            <tr>
                              <th>Sph</th>
                              <th>Cyl</th>
                              <th>Axis</th>
                              <th>Add Power</th>
                              <th>VA</th>
                            </tr>

                            <tr>
                              <td>
                                <input
                                  name="sph"
                                  id="sph"
                                  value={
                                    reading1RE && reading1RE.sph
                                      ? reading1RE.sph
                                      : ""
                                  }
                                  editable={false}
                                  type="text"
                                />
                              </td>
                              <td>
                                <input
                                  name="cyl"
                                  id="cyl"
                                  value={
                                    reading1RE && reading1RE.cyl
                                      ? reading1RE.cyl
                                      : ""
                                  }
                                  editable={false}
                                  type="text"
                                />
                              </td>
                              <td>
                                <input
                                  name="axis"
                                  id="axis"
                                  value={
                                    reading1RE && reading1RE.axis
                                      ? reading1RE.axis
                                      : ""
                                  }
                                  editable={false}
                                  type="text"
                                />
                              </td>
                              <td>
                                <input
                                  name="addPower"
                                  id="addPower"
                                  value={
                                    reading1RE && reading1RE.addPower
                                      ? reading1RE.addPower
                                      : ""
                                  }
                                  editable={false}
                                  type="text"
                                />
                              </td>
                              <td>
                                <input
                                  name="vA"
                                  id="vA"
                                  value={
                                    reading1RE && reading1RE.vA
                                      ? reading1RE.vA
                                      : ""
                                  }
                                  editable={false}
                                  type="text"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  name="sph"
                                  id="sph"
                                  value={
                                    reading2RE && reading2RE.sph
                                      ? reading2RE.sph
                                      : ""
                                  }
                                  editable={false}
                                  type="text"
                                />
                              </td>
                              <td>
                                <input
                                  name="cyl"
                                  id="cyl"
                                  value={
                                    reading2RE && reading2RE.cyl
                                      ? reading2RE.cyl
                                      : ""
                                  }
                                  editable={false}
                                  type="text"
                                />
                              </td>
                              <td>
                                <input
                                  name="axis"
                                  id="axis"
                                  value={
                                    reading2RE && reading2RE.axis
                                      ? reading2RE.axis
                                      : ""
                                  }
                                  editable={false}
                                  type="text"
                                />
                              </td>
                              <td>
                                <input
                                  name="addPower"
                                  id="addPower"
                                  value={
                                    reading2RE && reading2RE.addPower
                                      ? reading2RE.addPower
                                      : ""
                                  }
                                  editable={false}
                                  type="text"
                                />
                              </td>
                              <td>
                                <input
                                  name="vA"
                                  id="vA"
                                  value={
                                    reading2RE && reading2RE.vA
                                      ? reading2RE.vA
                                      : ""
                                  }
                                  editable={false}
                                  type="text"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  name="sph"
                                  id="sph"
                                  value={
                                    reading3RE && reading3RE.sph
                                      ? reading3RE.sph
                                      : ""
                                  }
                                  editable={false}
                                  type="text"
                                />
                              </td>
                              <td>
                                <input
                                  name="cyl"
                                  id="cyl"
                                  value={
                                    reading3RE && reading3RE.cyl
                                      ? reading3RE.cyl
                                      : ""
                                  }
                                  editable={false}
                                  type="text"
                                />
                              </td>
                              <td>
                                <input
                                  name="axis"
                                  id="axis"
                                  value={
                                    reading3RE && reading3RE.axis
                                      ? reading3RE.axis
                                      : ""
                                  }
                                  editable={false}
                                  type="text"
                                />
                              </td>
                              <td>
                                <input
                                  name="addPower"
                                  id="addPower"
                                  value={
                                    reading3RE && reading3RE.addPower
                                      ? reading3RE.addPower
                                      : ""
                                  }
                                  editable={false}
                                  type="text"
                                />
                              </td>
                              <td>
                                <input
                                  name="vA"
                                  id="vA"
                                  value={
                                    reading3RE && reading3RE.vA
                                      ? reading3RE.vA
                                      : ""
                                  }
                                  editable={false}
                                  type="text"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  name="sph"
                                  id="sph"
                                  value={
                                    readingFinalRE && readingFinalRE.sph
                                      ? readingFinalRE.sph
                                      : ""
                                  }
                                  editable={false}
                                  type="text"
                                />
                              </td>
                              <td>
                                <input
                                  name="cyl"
                                  id="cyl"
                                  value={
                                    readingFinalRE && readingFinalRE.cyl
                                      ? readingFinalRE.cyl
                                      : ""
                                  }
                                  editable={false}
                                  type="text"
                                />
                              </td>
                              <td>
                                <input
                                  name="axis"
                                  id="axis"
                                  value={
                                    readingFinalRE && readingFinalRE.axis
                                      ? readingFinalRE.axis
                                      : ""
                                  }
                                  editable={false}
                                  type="text"
                                />
                              </td>
                              <td>
                                <input
                                  name="addPower"
                                  id="addPower"
                                  value={
                                    readingFinalRE && readingFinalRE.addPower
                                      ? readingFinalRE.addPower
                                      : ""
                                  }
                                  editable={false}
                                  type="text"
                                />
                              </td>
                              <td>
                                <input
                                  name="vA"
                                  id="vA"
                                  value={
                                    readingFinalRE && readingFinalRE.vA
                                      ? readingFinalRE.vA
                                      : ""
                                  }
                                  editable={false}
                                  type="text"
                                />
                              </td>
                            </tr>
                          </table>
                        </div>
                        <div className="border border-gray-200  rounded-lg pb-3">
                          <table className="newtable">
                            <tr>
                              <th>Sph</th>
                              <th>Cyl</th>
                              <th>Axis</th>
                              <th>Add Power</th>
                              <th>VA</th>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  name="sph"
                                  id="sph"
                                  value={
                                    reading1LE && reading1LE.sph
                                      ? reading1LE.sph
                                      : ""
                                  }
                                  editable={false}
                                  type="text"
                                />
                              </td>
                              <td>
                                <input
                                  name="cyl"
                                  id="cyl"
                                  value={
                                    reading1LE && reading1LE.cyl
                                      ? reading1LE.cyl
                                      : ""
                                  }
                                  editable={false}
                                  type="text"
                                />
                              </td>
                              <td>
                                <input
                                  name="axis"
                                  id="axis"
                                  value={
                                    reading1LE && reading1LE.axis
                                      ? reading1LE.axis
                                      : ""
                                  }
                                  editable={false}
                                  type="text"
                                />
                              </td>
                              <td>
                                <input
                                  name="addPower"
                                  id="addPower"
                                  value={
                                    reading1LE && reading1LE.addPower
                                      ? reading1LE.addPower
                                      : ""
                                  }
                                  editable={false}
                                  type="text"
                                />
                              </td>
                              <td>
                                <input
                                  name="vA"
                                  id="vA"
                                  value={
                                    reading1LE && reading1LE.vA
                                      ? reading1LE.vA
                                      : ""
                                  }
                                  editable={false}
                                  type="text"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  name="sph"
                                  id="sph"
                                  value={
                                    reading2LE && reading2LE.sph
                                      ? reading2LE.sph
                                      : ""
                                  }
                                  editable={false}
                                  type="text"
                                />
                              </td>
                              <td>
                                <input
                                  name="cyl"
                                  id="cyl"
                                  value={
                                    reading2LE && reading2LE.cyl
                                      ? reading2LE.cyl
                                      : ""
                                  }
                                  editable={false}
                                  type="text"
                                />
                              </td>
                              <td>
                                <input
                                  name="axis"
                                  id="axis"
                                  value={
                                    reading2LE && reading2LE.axis
                                      ? reading2LE.axis
                                      : ""
                                  }
                                  editable={false}
                                  type="text"
                                />
                              </td>
                              <td>
                                <input
                                  name="addPower"
                                  id="addPower"
                                  value={
                                    reading2LE && reading2LE.addPower
                                      ? reading2LE.addPower
                                      : ""
                                  }
                                  editable={false}
                                  type="text"
                                />
                              </td>
                              <td>
                                <input
                                  name="vA"
                                  id="vA"
                                  value={
                                    reading2LE && reading2LE.vA
                                      ? reading2LE.vA
                                      : ""
                                  }
                                  editable={false}
                                  type="text"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  name="sph"
                                  id="sph"
                                  value={
                                    reading3LE && reading3LE.sph
                                      ? reading3LE.sph
                                      : ""
                                  }
                                  editable={false}
                                  type="text"
                                />
                              </td>
                              <td>
                                <input
                                  name="cyl"
                                  id="cyl"
                                  value={
                                    reading3LE && reading3LE.cyl
                                      ? reading3LE.cyl
                                      : ""
                                  }
                                  editable={false}
                                  type="text"
                                />
                              </td>
                              <td>
                                <input
                                  name="axis"
                                  id="axis"
                                  value={
                                    reading3LE && reading3LE.axis
                                      ? reading3LE.axis
                                      : ""
                                  }
                                  editable={false}
                                  type="text"
                                />
                              </td>
                              <td>
                                <input
                                  name="addPower"
                                  id="addPower"
                                  value={
                                    reading3LE && reading3LE.addPower
                                      ? reading3LE.addPower
                                      : ""
                                  }
                                  editable={false}
                                  type="text"
                                />
                              </td>
                              <td>
                                <input
                                  name="vA"
                                  id="vA"
                                  value={
                                    reading3LE && reading3LE.vA
                                      ? reading3LE.vA
                                      : ""
                                  }
                                  editable={false}
                                  type="text"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  name="sph"
                                  id="sph"
                                  value={
                                    readingFinalLE && readingFinalLE.sph
                                      ? readingFinalLE.sph
                                      : ""
                                  }
                                  editable={false}
                                  type="text"
                                />
                              </td>
                              <td>
                                <input
                                  name="cyl"
                                  id="cyl"
                                  value={
                                    readingFinalLE && readingFinalLE.cyl
                                      ? readingFinalLE.cyl
                                      : ""
                                  }
                                  editable={false}
                                  type="text"
                                />
                              </td>
                              <td>
                                <input
                                  name="axis"
                                  id="axis"
                                  value={
                                    readingFinalLE && readingFinalLE.axis
                                      ? readingFinalLE.axis
                                      : ""
                                  }
                                  editable={false}
                                  type="text"
                                />
                              </td>
                              <td>
                                <input
                                  name="addPower"
                                  id="addPower"
                                  value={
                                    readingFinalLE && readingFinalLE.addPower
                                      ? readingFinalLE.addPower
                                      : ""
                                  }
                                  editable={false}
                                  type="text"
                                />
                              </td>
                              <td>
                                <input
                                  name="vA"
                                  id="vA"
                                  value={
                                    readingFinalLE && readingFinalLE.vA
                                      ? readingFinalLE.vA
                                      : ""
                                  }
                                  editable={false}
                                  type="text"
                                />
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="grid grid-cols-2 gap-5">
                {postConsultation?.frame && (
                  <div className="mb-5">
                    <p className="underline underline-offset-1">Frame: </p>
                    <p className="text-base font-montserrat  font-thin ">
                      {postConsultation?.frame}
                    </p>
                  </div>
                )}
                {postConsultation?.coating && (
                  <div className="mb-5">
                    <p className="underline underline-offset-1">Coating: </p>
                    <p className="text-base font-montserrat  font-thin ">
                      {postConsultation?.coating}
                    </p>
                  </div>
                )}
                {postConsultation?.nvAssessment && (
                  <div className="mb-5">
                    <p className="underline underline-offset-1">
                      NV Assessment:{" "}
                    </p>
                    <p className="text-base font-montserrat  font-thin ">
                      {postConsultation?.nvAssessment}
                    </p>
                  </div>
                )}
                {postConsultation?.dvAssessment && (
                  <div className="mb-5">
                    <p className="underline underline-offset-1">
                      DV Assessment:{" "}
                    </p>
                    <p className="text-base font-montserrat  font-thin ">
                      {postConsultation?.dvAssessment}
                    </p>
                  </div>
                )}
                {postConsultation?.treatmentOption && (
                  <div className="mb-5">
                    <p className="underline underline-offset-1">
                      Treatment Option:{" "}
                    </p>
                    <p className="text-base font-montserrat  font-thin ">
                      {postConsultation?.treatmentOption}
                    </p>
                  </div>
                )}
                {postConsultation?.surgeryRecommendation && (
                  <div className="mb-5">
                    <p className="underline underline-offset-1">
                      Any Surgery Recommendation?:{" "}
                    </p>
                    <p className="text-base font-montserrat  font-thin ">
                      {postConsultation?.surgeryRecommendation}
                    </p>
                  </div>
                )}
              </div>
              <div className="my-2">
                <div className="w-6/6  my-2">
                  {/* <div>
                    <p className="">Cheif Complaint</p>
                    <p className="text-base font-montserrat  font-thin ">
                      {postConsultation?.consultReason}
                    </p>
                  </div> */}

                  {postConsultation?.recommendation && (
                    <div className="mb-5">
                      <p className="underline underline-offset-1">
                        <b className="underline underline-offset-1">
                          Recommendation :
                        </b>{" "}
                      </p>
                      <p className="text-base font-montserrat  font-thin ">
                        {postConsultation?.recommendation}
                      </p>
                    </div>
                  )}

                  {postConsultation?.followUpVisitDate && (
                    <div>
                      <p className=" my-2">
                        <b className="underline underline-offset-1">
                          Scheduled follow up visit date :
                        </b>{" "}
                        {moment(postConsultation?.followUpVisitDate).format(
                          "DD/MM/YYYY"
                        )}
                      </p>
                    </div>
                  )}
                </div>
              </div>

              {props && props.data ? (
                ""
              ) : (
                <div className="flex  justify-between">
                  <div className="mt-6">
                    {stamp && (
                      <img
                        // onClick={download}
                        // src={IMG_URL + postConsultation?.hospitalStamp}
                        src={`data:image/jpeg;base64,${postConsultation?.hospitalStampB64}`}
                        alt="Stamp"
                        width={150}
                      />
                    )}
                  </div>

                  <div className="mt-6">
                    {signature && (
                      <>
                        <img
                          // onClick={download}
                          // src={IMG_URL + postConsultation?.userSignature}
                          src={`data:image/jpeg;base64,${postConsultation?.userSignatureB64}`}
                          alt="Signature"
                          width={150}
                        />
                        <p className="font-rubik text-gray-primary text-base">
                          Dr.{postConsultation?.userName}
                        </p>
                        <p className="font-rubik text-gray-primary text-base">
                          {postConsultation?.userQualification}{" "}
                          {postConsultation?.userSpecialityDept}
                        </p>
                        <p className="font-rubik text-gray-primary text-base">
                          Regn No. {postConsultation?.userMCIVerification}
                        </p>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>

            {/* <div className="grid grid-cols-1 ">
              <div className="px-1 py-1">
                <p className="text-gray-500 text-sm ">
                  <b>Disclaimer :</b> This is an ONLINE consultation response.
                  The patient has not been physically examined. The
                  prescriptionor advice is based on the patient's description of
                  the problem which is given above and also explained over video
                  consultation{" "}
                </p>
              </div>
            </div> */}

            <div className="grid grid-cols-1 ">
              <div className="px-1 py-1">
                {postConsultation?.consultationType === "I" ? (
                  <span> </span>
                ) : (
                  <p className="text-gray-500 text-sm ">
                    <b>Disclaimer :</b>
                    <ul className="list-disc ml-8">
                      <li>
                        The information and advice provided here is provisional
                        in nature as it is based on the limited information made
                        available by the patient
                      </li>
                      <li>
                        The patient is advised to visit in person for thorough
                        examination at the earliest
                      </li>
                      <li>
                        The information is confidential in nature and for
                        recipient's use only
                      </li>
                      <li>
                        The Prescription is generated on a Teleconsultation
                      </li>
                      <li>Not valid for medico - legal purpose</li>{" "}
                    </ul>
                  </p>
                )}
              </div>
            </div>
          </div>
          {props && props.data ? (
            <div className="flex justify-end w-11/12">
              <button
                onClick={() => props.onClose()}
                className="bg-transparent text-base rounded-lg  mt-10 mr-5 w-52 float-right text-brand-secondary  font-medium  py-2 px-12 border border-brand-secondary "
              >
                Close{" "}
              </button>
            </div>
          ) : (
            <div className="flex justify-end w-11/12">
              <button
                onClick={download}
                className="bg-transparent text-base rounded-lg  mt-10 mr-5 w-52 float-right text-brand-secondary  font-medium  py-2 px-12 border border-brand-secondary "
              >
                Download{" "}
              </button>
              {!state.hidesubmit && (
                <button
                  onClick={() => history.push(APP_ROUTES.DASHBOARD)}
                  className="bg-brand-secondary rounded-lg mt-10 mr-5 w-52 float-right text-white font-medium py-2 px-10 rounded text-base"
                >
                  Submit
                </button>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
}
export default ConsulatationReport;
